import { FC } from "react";
import cx from "classnames";
import styles from "./finance-info-block.module.scss";
import { Vehicle } from "../../../types/vehicle";
import { formatNumber } from "../../../utils/stringFormatter";
import GoBackIcon from "../../icons/go-back-icon";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";

interface IFinanceInfoBlock {
  currentVehicle?: Vehicle;
  backButtonHandler?: () => void;
  dealerPhoto?: string;
  translations: Translations;
}

const FinanceInfoBlock: FC<IFinanceInfoBlock> = ({ currentVehicle, backButtonHandler, dealerPhoto, translations }) => {
  const t_common = getTranslationModule(translations, "common");
  const t_block = getTranslationModule(translations, "finance-calculator");
  const photos = currentVehicle?.photosMedium || currentVehicle?.photos;

  const handleOnImageLoadError = (e: any) => {
    e.target.src = `/images/brands/${currentVehicle?.make.toLowerCase()}.png` || "/images/out-of-stock.png";
  };

  return (
    <section className={styles.container}>
      <h4 className={styles.title}>
        {currentVehicle?.year +
          " " +
          currentVehicle?.make +
          " " +
          currentVehicle?.model +
          " " +
          currentVehicle?.drivetrain}
      </h4>
      <div
        className={cx(styles.image, {
          [styles.imageLogo]: photos?.length === 0,
        })}
      >
        <img
          src={
            photos?.[0] ||
            dealerPhoto ||
            `/images/brands/${currentVehicle?.make.toLowerCase()}.png` ||
            "/images/out-of-stock.png"
          }
          alt=""
          onError={handleOnImageLoadError}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.infoContainer}>
          <span className={styles.infoTitle}>{t_block("sale_price")}</span>
          <span className={styles.infoValue}>{`$${formatNumber(currentVehicle?.price || 0)}`}</span>
        </div>
        {backButtonHandler && (
          <button
            aria-label={"go to previous step"}
            name={"financeCalculatorGoToPreviousStep"}
            className={styles.goBack}
            onClick={backButtonHandler}
          >
            <GoBackIcon />
            {t_common("back")}
          </button>
        )}
      </div>
    </section>
  );
};

export default FinanceInfoBlock;

import { Dispatch, SetStateAction, useState } from "react";

export function useStateWithDelay<S>(initialState: S, delay: number): [S, S, Dispatch<SetStateAction<S>>] {
  const [stateValue, setRawState] = useState(initialState);
  const [delayedState, setDelayedState] = useState(initialState);
  const [timerId, setTimerId] = useState<any>(undefined);
  const updateState = (state: SetStateAction<S>): void => {
    setRawState(state);
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    const timer = setTimeout(() => {
      setTimerId(undefined);
      setDelayedState(state);
    }, delay);
    setTimerId(timer);
  };

  return [stateValue, delayedState, updateState];
}

import { FC } from "react";
import cx from "classnames";
import styles from "./thank-you.module.scss";
import Button from "../../../../common-components/button";
import DoneIcon from "../../../../icons/done-icon";
import { Translations } from "../../../../../utils/translations";
import { getTranslationModule } from "../../../../../utils/translations";

interface IThankYou {
  handleClose: (e: React.UIEvent) => void;
  title?: string;
  subtitle?: string;
  finance?: boolean;
  translations: Translations;
}

const ThankYou: FC<IThankYou> = ({ handleClose, title, subtitle, finance, translations }) => {
  const t = getTranslationModule(translations, "common");

  return (
    <section
      className={cx(styles.container, {
        [styles.finance]: finance,
      })}
    >
      {finance && <DoneIcon />}
      <h4 className={styles.title}>{title || t("thank_you")}</h4>
      <p className={styles.text}>{subtitle || t("someone_from_dealership")}</p>
      <Button
        name={"done"}
        ariaLabel={t("done")}
        type={"primary"}
        text={t("done")}
        className={styles.done}
        onClick={handleClose}
      />
    </section>
  );
};

export default ThankYou;

import { useState, FC, useEffect, useCallback, ChangeEvent } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import ErrorIcon from "../../icons/error-icon";
import { phoneFormat, socialNumberFormat } from "../../../utils/validators";

interface IInput {
  id?: string;
  name: string;
  className?: string;
  placeholder?: string;
  type: string;
  label?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  description?: string;
  errorMessage?: string;
  validated?: boolean;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  max?: number;
}

const Input: FC<IInput> = ({
  id,
  name,
  className,
  placeholder,
  type,
  label,
  value,
  onChange,
  onBlur,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  description,
  errorMessage,
  validated,
  required,
  disabled,
  maxLength,
  max,
}) => {
  const [curValue, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (type === "phone") {
        setValue(phoneFormat(e.target.value));
      } else if (name === "socialNumber") {
        setValue(socialNumberFormat(e.target.value));
      } else if (name === ("zipCode" || "zip")) {
        const slicedValue = e.target.value.slice(0, 5);
        setValue(slicedValue);
      } else if (name === "year") {
        const slicedValue = e.target.value.slice(0, 4);
        setValue(slicedValue);
      } else if (name === "rentAmount" || name === "annualIncome") {
        if (/\D/g.test(e.target.value)) {
          // Filter non-digits from input value.
          e.target.value = e.target.value.replace(/\D/g, "");
        }
        let parsed = e.target.value.replace(/,/g, "");
        setValue(parsed === "" ? "" : new Intl.NumberFormat().format(parseInt(parsed)));
      } else if (name === "financePrice" || name === "downPayment") {
        const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
        const trimmedValue = parseInt(sanitizedValue, 10).toString();
        e.target.value = trimmedValue;
      } else {
        setValue(e.target.value);
      }

      if (onChange) {
        onChange(e);
      }
    },
    [onChange, type, name],
  );

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {required ? label + " *" : label}
        </label>
      )}
      <div className={styles.inputContainer}>
        <input
          id={id}
          className={cx(styles.input, { [styles.error]: !validated })}
          name={name}
          placeholder={placeholder}
          type={type}
          value={curValue}
          onChange={changeHandler}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          max={max}
        />
        {!validated && <ErrorIcon className={styles.errorIcon} />}
        {!validated && <p className={styles.errorMessage}>{errorMessage}</p>}
        {description && validated && <p className={styles.description}>{description}</p>}
      </div>
    </div>
  );
};

export default Input;
